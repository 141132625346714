<template>
    <div class="container">
        <div class="row my-5">
            <div class="col-md-8">
                <h3 class="font-weight-bold">Child Checkin</h3>
            </div>
            <div class="col-md-4 d-md-flex justify-content-end">
                <button class="default-btn border-0 text-white font-weight-bold primary-bg"
                    data-toggle="modal" data-target="#exampleModal"
                >
                    Add Family
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    setup () {
        

        return {}
    }
}
</script>

<style scoped>

</style>